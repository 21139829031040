//
// Forms.scss
//

[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  &::placeholder {
    text-align: left
      /*rtl: right*/
    ;
  }
}

.form-check {
  position: relative;
  text-align: left
    /*rtl: right*/
  ;
}

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;

  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }

  .form-check-label {
    display: block;
  }
}

// checkbox

.form-checkbox-outline {
  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type="checkbox"] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: "\F012C";
        font-family: "Material Design Icons";
        top: -4px !important;
        left: 1px;
        /*rtl: -4px */
        font-size: 16px;
        color: $dark;
      }
    }
  }
}

// radio

.form-radio-outline {
  .form-check-input {
    background-color: $card-bg;
    position: relative;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type="checkbox"] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: "";
        top: 3px !important;
        left: 3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}

// checkbox color

@each $color, $value in $theme-colors {
  .form-check-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        border-color: $value;
        background-color: $value;

        &:after {
          background-color: $value;
        }
      }
    }
  }
}

.form-check,
.form-check-input,
.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

// Switch sizes

.form-switch-md {
  padding-left: 2.5rem;
  min-height: 24px;
  line-height: 24px;

  .form-check-input {
    width: 40px;
    height: 20px;
    left: -0.5rem;
    position: relative;
  }

  .form-check-label {
    vertical-align: middle;
  }
}

.form-switch-lg {
  padding-left: 2.75rem;
  min-height: 28px;
  line-height: 28px;

  .form-check-input {
    width: 48px;
    height: 24px;
    left: -0.75rem;
    position: relative;
  }
}

.input-group-text {
  margin-bottom: 0px;
}

// transfer list

.rdl-filter,
.rdl-control {
  background-color: $input-bg;
  border-color: $input-border-color;
  color: $input-color;
}

.react-dual-listbox {
  button {
    color: $dark;
    border-color: $border-color;
    background-color: $light;
  }

  input,
  select {
    &:disabled {
      background-color: $input-disabled-bg;
    }
  }
}

.rdl-move {

  .form-check-input,
  .form-check-input:focus {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$gray-400}'/></svg>"));
  }
}

.custom-select-validation {
  border: 1px solid #f46a6a !important;
}


.disabled-select .select__control {
  border: 2px solid #ced4da !important;
  background-color: #eff2f7 !important;
}

.disabled-select .css-1p3m7a8-multiValue {
  background-color: #eef0f3 !important;
  border: 1px solid #cfcfcf;
}

.disabled-select .css-1p3m7a8-multiValue .css-wsp0cs-MultiValueGeneric {
  color: #74788d !important;
}

.closeIconRemove {
  .select__multi-value__remove {
    display: none !important;
  }
}

.select__multi-value--is-disabled {
  padding-right: 3px;
}