 .ql-editor {
     min-height: 150px;
     max-height: 300px;
 }

 .ql-toolbar {
     background-color: rgb(241, 241, 241);
     border-radius: 7px;
 }
 .ql-container {
    /* background-color: rgb(0, 128, 255); */
    border-radius: px;
}
/* 
 @import url('https://fonts.googleapis.com/css?family=Arial&display=swap');
 @import url('https://fonts.googleapis.com/css?family=Helvetica&display=swap');
 @import url('https://fonts.googleapis.com/css?family=Serif&display=swap');
 @import url('https://fonts.googleapis.com/css?family=Courier&display=swap');
 @import url('https://fonts.googleapis.com/css?family=monospace&display=swap');
 @import url('https://fonts.googleapis.com/css?family=Robot&display=swap');
 @import url('https://fonts.googleapis.com/css?family=Verdana&display=swap'); */


 .ql-picker.ql-font {
     .ql-picker-item {
         font-size: 0;

         &:before {
             content: attr(data-value) !important;
             font-size: 14px;
         }
     }
 }

 .ql-picker.ql-font {
     .ql-active {
         &:before {
             content: attr(data-value) !important;
             font-size: 14px;
         }
     }
 }

 .ql-picker.ql-font .ql-picker-label[data-value="Areal"]::before,
 .ql-picker.ql-font .ql-picker-item[data-value="Areal"]::before {
     font-family: "Areal", cursive;
     ;
     content: "Areal" !important;
 }

 .ql-picker.ql-font .ql-picker-label[data-value="Helvetica"]::before,
 .ql-picker.ql-font .ql-picker-item[data-value="Helvetica"]::before {
     font-family: "Helvetica", cursive;
     ;
     content: "Helvetica" !important;
 }

 .ql-picker.ql-font .ql-picker-label[data-value="Serif"]::before,
 .ql-picker.ql-font .ql-picker-item[data-value="Serif"]::before {
     font-family: "Serif", cursive;
     ;
     content: "Serif" !important;
 }

 .ql-picker.ql-font .ql-picker-label[data-value="Courier"]::before,
 .ql-picker.ql-font .ql-picker-item[data-value="Courier"]::before {
     font-family: "Courier", cursive;
     ;
     content: "Courier" !important;
 }

 .ql-picker.ql-font .ql-picker-label[data-value="Verdana"]::before,
 .ql-picker.ql-font .ql-picker-item[data-value="Verdana"]::before {
     font-family: "Verdana", cursive;
     ;
     content: "Verdana" !important;
 }

 .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
 .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
     font-family: "monospace", cursive;
     ;
     content: "monospace" !important;
 }

 .ql-picker.ql-font .ql-picker-label[data-value="Robot"]::before,
 .ql-picker.ql-font .ql-picker-item[data-value="Robot"]::before {
     font-family: "Robot", cursive;
     ;
     content: "Robot" !important;
 }

 /* Set content font-families */
 .ql-font-Areal {
     font-family: "Areal";
 }

 .ql-font-Helvetica {
     font-family: "Helvetica";
 }

 .ql-font-Serif {
     font-family: "Serif";
 }

 .ql-font-Courier {
     font-family: "Courier";
 }

 .ql-font-Verdana {
     font-family: "Verdana";
 }

 .ql-font-Courier {
     font-family: "monospace";
 }

 .ql-font-Verdana {
     font-family: "Robot";
 }
 .ql-indent-1{
    padding-left: 1em;
 }
 .ql-indent-2{
    padding-left: 2em;
 }
 .ql-indent-3{
    padding-left: 3em;
 }
 .ql-indent-4{
    padding-left: 4em;
 }
 .ql-indent-5{
    padding-left: 5em;
 }
