$pac-container-bg-color: #FFF;
$pac-container-z-index: 2001;
$modal-z-index: 2000;
$modal-backdrop-z-index: 1000;

.pac-container {
  background-color: $pac-container-bg-color;
  z-index: $pac-container-z-index;
  position: fixed;
  display: inline-block;
  float: left;
}

.modal {
  z-index: $modal-z-index;
}

.modal-backdrop {
  z-index: $modal-backdrop-z-index;
}
