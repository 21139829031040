@import "../../assets/scss/theme.scss";

.radio-toolbar {
  label {
    display: inline-block;
    background-color: var(--#{$prefix}light);
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    position: relative;
  }
  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
    &:checked + label {
      background-color: $primary;
      border: none;
      color: $white;
    }
  }
  // color option
  &.coloropt-radio {
    input[type="radio"] {
      &:checked + label {
        &:after {
          content: "\F012C";
          position: absolute;
          font-family: "Material Design Icons";
          color: $white;
          font-size: 14px;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
        &.bg-light {
          &:after {
            color: $primary;
          }
        }
      }
    }

    label {
      border: 1px solid $card-bg !important;
      &.bg-light {
        background-color: $card-bg;
        border-color: $primary !important;
      }
    }
  }

  // image option
  &.imgopt-radio {
    label {
      padding: 0;
      overflow: hidden;
      background: transparent;
    }
    input[type="radio"] {
      &:checked + label {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($primary, 0.6);
        }

        &:after {
          content: "\F012C";
          position: absolute;
          font-family: "Material Design Icons";
          color: $white;
          font-size: 14px;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
}

#radio-title {
  margin-bottom: 5px;
}
.scroller-thin::-webkit-scrollbar {
    width: 5px;
    /* Adjust the width as needed */
}

/* Adjust the scrollbar styles */

.scroller-thin::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Scrollbar thumb color */
    border-radius: 4px;
    /* Rounded edges for thumb */
}

.scroller-thin::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Hover color for thumb */
}

.scroller-thin::-webkit-scrollbar-track {
    background-color: #ddd;
    /* Scrollbar track color */
    border-radius: 4px;
    /* Rounded edges for track */
}