.crose-img-btn {
    position: absolute;
    top: -17px;
    right: -2px;
    height: 36px !important;
    border-radius: 50%;
}

.properties-img-wrapper {
    // height: 320px;
    overflow: auto;
    padding: 20px 20px 0px 0;
}

.info-card-detail {
    background-color: #f1faff;
    border: 1px solid #0073bb !important;
}
  .option-container-email{
    font-size: 0.75rem; 
    
  }