@import "../../../assets/scss/theme.scss";

.images-responsive::-webkit-scrollbar {
    width: 5px;
    /* Adjust the width as needed */
}

/* Adjust the scrollbar styles */

.images-responsive::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Scrollbar thumb color */
    border-radius: 4px;
    /* Rounded edges for thumb */
}

.images-responsive::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Hover color for thumb */
}

.images-responsive::-webkit-scrollbar-track {
    background-color: #ddd;
    /* Scrollbar track color */
    border-radius: 4px;
    /* Rounded edges for track */
}