.crop-img-modal .modal-content{
    border: 1px solid #a2a2a2;
}

.crop-img-modal .modal-header{
    background-color: #f7f2ef;
    border-bottom: 1px solid #bfbfbf;
}

.crop-img-modal .modal-body{
    background-color: #f7f2ef !important;
}

.crop-img-modal .modal-footer{
    background-color: #f7f2ef;
    border-top: 1px solid #bfbfbf;
}

.crop-img-modal .pagination-btn{
    height: 30px;
    width: auto;
    min-width: 30px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #404040;
    color: #000;
    transition: .4s;

    &:hover{
        background-color: #194eca;
        color: #fff;
        transition: .4s;
        border: 1px solid #194eca;
    }
}

.profileimage-wrapper {
    position: relative;
    margin-right: 34px;

    img {
      height: 96px;
      width: 96px !important;
      border-radius: 96px;
      object-fit: cover;
    }

    .btn.btn-editprofile {
      background-color: #fff;
      padding: 9px !important;
      border-radius: 100%;
      position: absolute;
      right: -10px;
      bottom: 15px;
      filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.08));
    }
  }